<template>
  <div class="animated fadeIn">
    <!-- <b-card :title="$t('message.title.allIncome')"> -->
    <b-card>
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t("message.transactionId") }} :
          <br />
          <b-form-input v-model="filters['id.contains']" />
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t("message.ksher_order_no") }}:
          <br />
          <b-form-input v-model="filters['ksher_order_no.contains']" />
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t("message.refNumber") }} :
          <br />
          <b-form-input v-model="filters['ref_number.contains']" />
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 my-1">
          {{ $t('message.date') }} :
          <br />
          <div class="table-responsive">
            <date-time-picker v-model="filters.date" type="datetime" width="100"></date-time-picker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1">
          {{ $t('message.machineId') }} :
          <multi-list-select :list="machineData" option-value="machineId" option-text="name" :selected-items="selectedMachine" placeholder="All" @select="onSelectMachine"></multi-list-select>
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 my-1">
          {{ $t("message.paymentType") }} :
          <br />
          <b-form-select v-model="filters['channel.in']" :options="channelOpt" />
        </div>
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 my-1">
          {{ $t("message.status") }} :
          <br />
          <b-form-select v-model="filters['status.equals']" :options="statusOpt" />
        </div>

        <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-2">
          <br />
          <b-button v-on:click="searchFn()" class="col-12 col-sm-6 col-md-3 col-lg-5" variant="primary">
            <i class="fa fa-search"></i>
            &nbsp;{{ $t('message.search') }}
          </b-button>&nbsp;
        </div>
      </div>

      <br />
      <hr />

      <div class="wrapper">
        <br />
        <br />
        <!-- ข้อมูลการเติมเงิน -->

        <!-- <div class="row">
          <div class="col-12 col-md-7">
            <b>{{ $t('message.top-upInformation') }}</b>
          </div>
        </div>-->

        <div class="row">
          <div class="col-12">
            <template>
              <div class="table-responsive">
                <Table :columns="columns" size="small" :data="data"></Table>
              </div>
              <br />
              <Page :total="totalRows" :page-size="itemPerPage" @on-change="searchFn" />
            </template>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import webServices from '../../script'
import { ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'

// import DateTimePicker from '../../components/DateTimePicker/DateTimePicker.vue'
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
// import DateTimePicker from '../../components/DateTimePicker_custom.vue'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

Vue.filter('pad', function (num, size) {
  num = num.toString()
  while (num.length < size) num = '0' + num
  return num
})

export default {
  name: 'OnlinePayment',
  components: {
    DateTimePicker,
    ModelListSelect,
    MultiListSelect
  },
  data() {
    return {
      itemPerPage: 20,
      totalRows: 0,
      statusOpt: [
        { value: '', text: 'ทั้งหมด' },
        { value: 'QRCODE_INIT', text: 'Process' },
        { value: 'PAYMENT_SUCCESS', text: 'Success' },
        { value: 'PAYMENT_TIMEOUT', text: 'Timeout' },
        { value: 'PAYMENT_SUCCESS_TIMEOUT', text: 'Late Payment' }
      ],
      channelOpt: [
        { value: '', text: 'ทั้งหมด' },
        { value: 'alipay', text: 'alipay' },
        // { value: 'bbl_promptpay', text: 'bbl_promptpay' },
        { value: 'promptpay,bbl_promptpay', text: 'promptpay' },
        { value: 'truemoney', text: 'truemoney' },
        { value: 'shopeepay', text: 'shopeepay' }
      ],
      selectedMachine: [],
      machineData: [],
      filters: {
        // date: [moment().startOf('day').subtract(1, 'months').toDate(), moment().endOf('day').toDate()]
        date: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
        'channel.in': '',
        'status.equals': ''
      },
      data: [],
      columns: [
        {
          title: this.$t('message.transactionId'),
          key: 'transactionId',
          align: 'center',
          minWidth: 100
        },
        {
          title: this.$t('message.ksher_order_no'),
          key: 'ksherOrderNo',
          align: 'center',
          minWidth: 100
        },
        {
          title: this.$t('message.refNumber'),
          key: 'refNumber',
          align: 'center'
        },
        {
          title: this.$t('message.machineId'),
          // key: 'machineId',
          align: 'center',
          render: (h, params) => {
            return h('span', Vue.filter('pad')(params.row.machineId, 6))
          }
        },
        {
          title: this.$t('message.paymentType'),
          align: 'center',
          // key: 'channel',
          render: (h, params) => this.showPaymentType(h, params.row.channel)
        },
        {
          title: this.$t('message.insert'),
          align: 'right',
          // key: 'totalFee'
          render: (h, params) => h('span', Vue.filter('formatNumber')(params.row.creditCMAfter))
        },
        {
          title: this.$t('message.status'),
          // key: 'status'
          align: 'center',
          render: (h, params) => this.showPaymentStatus(h, params.row.status)
        },
        {
          title: this.$t('message.createdAt'),
          // key: 'createdAt'
          align: 'center',
          render: (h, params) => h('span', moment(params.row.createdAt).format('YYYY-MM-DD HH:mm'))
        },
        {
          title: this.$t('message.updatedAt'),
          // key: 'updatedAt'
          align: 'center',
          render: (h, params) => h('span', moment(params.row.updatedAt).format('YYYY-MM-DD HH:mm'))
        }
      ]
    }
  },
  methods: {
    getMachine() {
      this.axios
        .get(`/machines/list?`)
        .then(res => {
          this.machineData = res.data
        })
        .catch(err => {
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
    },

    showAlert_ChooseOneBranch() {
      if (window.localStorage.getItem('language') == 'th') {
        var title = 'โปรดเลือกช่วงเวลาใหม่อีกครั้ง'
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = 'ปิด'
      } else {
        var title = 'Please select a new time again'
        var text = 'Cannot select more than 12 months.'
        var confirmButton = 'Close'
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    searchFn(page = 1) {
      this.$Progress.start()

      const from = moment(this.filters.date[0])
      const to = moment(this.filters.date[1])

      const params = {
        ...this.filters,
        page,
        'created_at.between': from.format() + ',' + to.format(),
        'machine_id.in': this.selectedMachine.map(data => Number(data.machineId)).join(',')
      }

      delete params.date

      if (from.diff(to, 'month') < 12) {
        this.axios
          .get(`/payment/transactions`, { params })
          .then(res => {
            this.$Progress.finish()
            // console.log(res)
            this.data = res.data.rows
            this.totalRows = res.data.totalRows
          })
          .catch(err => {
            this.$Progress.fail()
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
            this.$toast.error({
              title: 'ERROR',
              message: {
                error: err.response.data,
                code: err.response.status,
                text: err.response.statusText
              }
            })
            console.log('error @machine')
            console.log(err)
          })
      } else {
        console.log('เลือกเดือนได้สูงสุด 12 เดือน')
        this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      }
    },

    onSelectMachine(items) {
      this.selectedMachine = items
      // this.machineId = this.selectedMachine.map(data => data.machineId)
      // console.log('onSelectMachine = ', this.filters.machineId)
    },
    showPaymentType(h, type) {
      switch (type) {
        case 'bbl_promptpay':
          return h('Tag', { props: { color: 'primary' }, slot: 'extra' }, 'PromptPay')
        case 'promptpay':
          return h('Tag', { props: { color: 'primary' }, slot: 'extra' }, 'PromptPay')
        case 'truemoney':
          return h('Tag', { props: { color: 'red' }, slot: 'extra' }, 'Truemoney')
        case 'alipay':
          return h('Tag', { props: { color: 'blue' }, slot: 'extra' }, 'AliPay')
        case 'shopeepay':
          return h('Tag', { props: { color: 'warning' }, slot: 'extra' }, 'ShopeePay')
        default:
          return h('Tag', { props: { color: 'default' }, slot: 'extra' }, type)
      }
    },
    showPaymentStatus(h, status) {
      switch (status) {
        case 'QRCODE_INIT':
          return h('Tag', { props: { color: 'warning' } }, 'Process')
        case 'PAYMENT_SUCCESS':
          return h('Tag', { props: { color: 'success' } }, 'Success')
        case 'PAYMENT_TIMEOUT':
          return h('Tag', { props: { color: 'default' } }, 'Timeout')
        case 'PAYMENT_SUCCESS_TIMEOUT':
          return h('Tag', { props: { color: 'error' } }, 'Late Payment')
        default:
          return h('Tag', params.row.status)
      }
    }
  },
  mounted() {
    this.getMachine()
  }
}
</script>

<style>
@import './styleRe.css';
</style>
